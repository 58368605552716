<template>
  <div class="container">
    <div class="header">
      <div class="header_left">
        <span class="task_title">任务管理</span>
        <span class="task_icon">-</span>
        <span class="task_title">查看明细</span>
        <span class="task_icon">-</span>
        <span class="nav">查看</span>
      </div>
      <div class="header_right" @click="goback">
        <img src="../../../../../assets/images/back.png" alt="" />
        <span class="goback">返回上一层</span>
      </div>
    </div>
    <div class="content">
      <div class="form">
        <a-form-model :model="watchTaskForm">
          <div class="layout">
            <a-form-model-item label="任务名称">
              <a-input disabled v-model="watchTaskForm.echoMap.task.name"></a-input>
            </a-form-model-item>
            <a-form-model-item label="任务积分">
              <a-input disabled placeholder="0.0" v-model="taskintegration" />
            </a-form-model-item>
          </div>
          <div class="layout">
            <a-form-model-item label="任务类型">
              <a-input disabled placeholder="未填写" v-model="watchTaskForm.echoMap.typeName" />
            </a-form-model-item>
            <a-form-model-item label="获得积分">
              <a-input disabled placeholder="0.0" v-model="getintegration" />
            </a-form-model-item>
          </div>
          <div class="layout">
            <a-form-model-item label="任务状态">
              <a-input disabled placeholder="未填写" v-model="watchTaskForm.echoMap.state" />
            </a-form-model-item>
            <a-form-model-item label="服务时间">
              <a-input disabled placeholder="未填写" v-model="watchTaskForm.serviceTime" />
            </a-form-model-item>
          </div>
          <a-form-model-item label="服务内容">
            <a-textarea disabled placeholder="未填写" style="height:96px;width:340px" :rows="5" v-model="watchTaskForm.serviceContent" />
          </a-form-model-item>
          <a-form-model-item class="showimg" label="图片预览">
            <div class="imgbox">
              <img @click="imgshow(item)" class="img" v-for="item in pictureList" :src="item" alt="" />
            </div>
            <a-modal :visible="previewVisible" :footer="null" @cancel="imgCancel" centered>
              <img alt="example" class="imgexample" style="width:100%" :src="previewImage" />
            </a-modal>
          </a-form-model-item>
          <div class="btn" @click="goback">返回上一层</div>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import local from '@/utils/local'
import { getUserTask } from '@/api/task'
export default {
  data() {
    return {
      id: 0,
      watchTaskForm: {},
      pictureList: '',
      // 获得积分
      getintegration: '',
      // 任务积分
      taskintegration: '',
      previewVisible: false,
      previewImage: ''
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getUserTask()
    }
  },
  methods: {
    goback() {
      const Id = local.get('Id')
      this.$router.push(`/member/task/watch/list?id=${Id}`)
    },
    imgCancel() {
      this.previewVisible = false
    },
    imgshow(url) {
      this.previewImage = url
      this.previewVisible = true
    },
    async getUserTask() {
      const data = await getUserTask({ id: this.id })
      if (data.code === 200) {
        this.watchTaskForm = data.data
        this.getintegration = data.data.echoMap.integration
        if (this.getintegration.substr(0, 1) == '+') {
          this.getintegration = Number(this.getintegration.substring(1)).toFixed(1)
          console.log(this.getintegration)
        } else if (this.getintegration.substr(0, 1) == '-') {
          this.getintegration = '-' + Number(this.getintegration.substring(1)).toFixed(1)
        } else {
          this.getintegration = data.data.echoMap.integration
        }
        this.taskintegration = data.data.integration.toFixed(1)
        if (data.data.picture !== null) {
          this.pictureList = data.data.picture.split(',')
        }
        console.log(this.watchTaskForm, 'form')
      }
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  padding: 0 0 20px 0;
}

.header_left {
  width: 185px;
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
}

.task_title,
.task_icon {
  color: #888888;
}

.header_right {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nav {
  color: #333333;
}

.goback {
  color: #f44600;
  margin-right: 6px;
  padding: 1px 0 0 0;
}

.content {
  background: #ffffff;
  box-sizing: border-box;
  padding: 40px 50px;
  height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

>>> .ant-form-item {
  display: flex;
}

/* >>>.ant-form-item-control {
    width: 340px;
   */
.ant-form-item >>> .ant-input {
  width: 340px;
  height: 36px;
}

>>> .ant-select-selection__rendered {
  width: 340px;
  height: 36px;
}

>>> .ant-select-selection--single {
  width: 340px;
  height: 36px;
}

.form {
  width: 700px;
  display: flex;
}

.layout {
  width: 920px;
  display: flex;
  justify-content: space-between;
}

.form >>> .ant-input {
  background-color: #ffffff;
}
.img {
  width: 80px;
  height: 80px;
  margin: 5px;
  cursor: pointer;
}
.btn {
  width: 110px;
  height: 36px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #d9dbde;
  color: #666666;
  margin: 50px 0 0 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.showimg > .imgbox {
  width: 100%;
  display: flex;
  box-sizing: border-box;
}
* >>> .ant-modal-close-x {
  width: 30px;
  line-height: 30px;
}
.ant-form-item >>> .ant-input-disabled {
  color: #666666;
}
.showimg >>> .ant-form-item-children {
  display: flex;
}
</style>
